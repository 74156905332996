import { ThemeProvider, createTheme } from "@mui/material";
import { LandingPage } from "./Pages";
import { grey, lime } from "@mui/material/colors";

const App = () => {
    const theme = createTheme({
        palette: {
            primary: grey,
            secondary: lime,
        },
        typography: {
            fontFamily: ["Chilanka", "cursive"].join(","),
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <LandingPage />
        </ThemeProvider>
    );
};

export default App;
